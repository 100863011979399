<template>
  <div>
    <Form class="form" @submit="onSubmit" :validation-schema="schema">
      <div class="grid-row">
        <div class="col-12_md-6">
          <FormInput field-name="oldPassword" field-label="舊密碼" field-type="password" />
        </div>
        <div class="col-12_md-6"></div>
        <div class="col-12_md-6">
          <FormInput field-name="password" field-label="新密碼" field-type="password">
            <template #notify>至少12碼，且包含英文大小寫、特殊符號與數字</template>
          </FormInput>
        </div>
        <div class="col-12_md-6">
          <FormInput field-name="confirmPassword" field-label="確認密碼" field-type="password" />
        </div>
      </div>
      <CaptchaInput :captcha-value="captcha?.value" @refresh-captcha="refreshCaptch"/>
      <div class="btn-group">
        <RouterLink to="/user" class="btn btn-secondary btn-md">返回</RouterLink>
        <button class="btn btn-primary btn-md">確認修改</button>
      </div>
    </Form>
  </div>
</template>
<script>
import { mapActions } from 'pinia';
import { useLoadingScreen } from '@/store/index';
import { useUserData } from '@/store/user';
import { updatePassword as schema } from '@/utils/schema';
import FormInput from '@/components/Input/FormInput.vue';
import CaptchaInput from '@/components/Input/CaptchaInput.vue';
import { Form } from 'vee-validate';
import api from '@/api/api';

export default {
  components: {
    Form,
    FormInput,
    CaptchaInput,
  },
  data() {
    return {
      schema,
      captcha: null,
    };
  },
  methods: {
    async onSubmit(formData) {
      this.setLoading(true);
      const captchaValid = await api.checkCaptcha({ key: this.captcha.key, value: formData.captcha });
      if (!captchaValid) {
        alert('驗證碼錯誤，請重新輸入');
        this.refreshCaptch();
        this.setLoading(false);
        return;
      }

      const isPassed = await this.updateUserPassword(formData);
      if (isPassed) {
        alert('變更成功');
        this.$router.push('/user/project');
      } else {
        alert('發生錯誤密碼未變更成功，請聯絡系統工程師');
      }
      this.setLoading(false);
    },
    refreshCaptch() {
      this.captcha = null;
      api.getCaptcha().then((data) => {
        this.captcha = data;
      });
    },
    ...mapActions(useLoadingScreen, ['setLoading']),
    ...mapActions(useUserData, ['updateUserPassword']),
  },
  created() {
    this.refreshCaptch();
  },
};
</script>
